<template>
 <div class="labbox">
  <div class="box">
    <img :src="data.baseUrl+data.enImg" class="labbackground">
    <Header :isActive="true"/>
    <i></i>
    <div class="breadcrumbBox">
      <img :src="data.baseUrl+'uploads/images/9dd88445c1063337cb2e501454c5ede2.png'">
      <div class="navcenter" ref="lfettitle">
        <div class="lefttext" :style="data.style">{{data.title_en}}</div>
        <div class="righttext">
          <span @click="router.push('/moelab')">首页</span>
          <span v-for="item in data.idPrenArr" :key="item.id">{{'\n > \n' + item.title_en}}</span>
        </div>
      </div>
    </div>
 </div>
   <div class="navbottom">
    <div>
      <div class="leftNavlist" ref="leftNavlist">
          <nav @click="leftnavHeaght(i,item)" v-for="(item,i) in data.leftNavs" :key="item.id">
              <em></em>
              <span>{{item.title_en}}</span>
              <i v-show="item.children"></i>
              <div class="towmenus" v-if="item.children">
                  <section v-for="(item2,i2) in item.children" :key="item2.id" @click.stop="setleftTwo(i2,item2,i,item)">{{item2.title_en}}</section>
              </div>
          </nav>
      </div>
    </div>
      <div class="rightRouterview" ref="rightRouterview">
            <router-view v-slot="{ Component }">
                <keep-alive>
                    <component :is="Component"  v-if="$route.meta.keepAlive"/>
                </keep-alive>
                <component :is="Component"  v-if="!$route.meta.keepAlive"/>
            </router-view>
        </div>
   </div>
   <pcfooter />
 </div>
</template>

<script setup>
import Header from '@/components/laboratoryCom/labheader.vue'
import pcfooter from '@/components/laboratoryCom/labfooter.vue'
import {ref,reactive,onMounted,watch,onUnmounted,nextTick} from 'vue'
import { useRouter,useRoute } from 'vue-router'
import * as http from '@/api/API.js'
import miiter from '@/utils/miitbus.js'
const router = useRouter()
const route = useRoute()
const data = reactive({
  baseUrl:process.env.VUE_APP_BASE_URL+'/',
  // 通过js写左侧背景样式
  style:{ 
    background:`url(${process.env.VUE_APP_BASE_URL+'/'+'uploads/images/50c14bfc5fe736366cc99fcf7bf99e11.jpg'})`,
    backgroundSize: '100% 100%',
    backgroundRepeat: 'no-repeat'
  },
  leftNavs:[
    {id:1,title_en:'实验室简介',children:false},
    {id:2,title_en:'实验室简介',children:false},
    {id:3,title_en:'实验室简介',children:false},
    {id:4,title_en:'实验室简介',children:false},
  ],
  enImg:'',
  title_en:'',
  homenavs:[
            {title_en:'综合新闻',id:385,tid:385,level:1,vue_url:'Moearticlelist'},
            {title_en:'通知公告',id:386,tid:386,level:1,vue_url:'Moearticlelist'},
            {title_en:'讲座报告',id:387,tid:387,level:1,vue_url:'Moearticlelist'},
            {title_en:'科研动态',id:388,tid:388,level:1,vue_url:'Moearticlelist'},
        ]
})
// 需要做动画的dom
const lfettitle = ref(null)
const leftNavlist = ref(null)
const rightRouterview = ref(null)
onMounted(() => {
    document.documentElement.scrollTop = 0
    animate(lfettitle.value,100)
    animate(leftNavlist.value,250)
    animate(rightRouterview.value,350)
    // 全局事件总线，动态修改二级页面
})
// 模拟进场动画函数，动画进场
const animate = (dom1,time) => {
    setTimeout(() => {
        dom1.style['transform'] = 'translateY(0)'
        dom1.style['opacity'] = '1'
    },time)
}
// 动画出场
const animateStr = (dom1,time) => {
    setTimeout(() => {
        dom1.style['opacity'] = '0'
        dom1.style['transform'] = 'translateY(800px)'
    },time)
}
// 点击一级菜单跳转对应页面
const leftnavHeaght = (e,item) => {
    setleftscri(e)
     // 如果有外部链接就跳转
  if(item.external_url){
    window.open(item.external_url)
    return
  }
    router.push({
        name: item.vue_url,
        params:{
          id:item.id,
          tid:item.tid,
          level:item.level,
          isen:2
        }
    })
    miiter.emit('onEnactilcetap',item.tid)
}
// 点击左侧二级菜单
const setleftTwo = (i2,item2,i,item) => {
    router.push({
        name: item2.vue_url,
        params:{
          id:item2.id,
          tid:item2.tid,
          level:item2.level,
          isen:2
        }
    })
    miiter.emit('onEnactilcetap')
}
// 点击左侧一级菜单设置样式
const setleftscri = (e) => {
    const ul = leftNavlist.value?leftNavlist.value.children:[] // 拿到当前ul元素
    const li = ul[e]// 拿到当前li元素
    const liChildren = li?li.children:[] // 拿到子级
    const towmenusHeight = liChildren[3]&&liChildren[3].offsetHeight // 左侧三级级元素的高度
    const liHeight = 71 // 每个li本身高度
    const lisetBottom = 10  // 动态设置高度时，额外加10px
    // 设置样式之前，恢复当前样式
    for(let i = 0; i < ul.length; i ++){
        setStyle(ul[i].children[0],'opacity',0)
        setStyle(ul[i].children[1],'color','#6e6e6e')
        setStyle(ul[i],'height',liHeight/128+'rem')
        setStyle(ul[i].children[2],'transform','rotate(0)')
        ul[i].children[3]&&setStyle(ul[i].children[3],'opacity',0)
        ul[i].children[3]&&setStyle(ul[i].children[3],'visibility','hidden')
    }
    // 设置样式
    setStyle(liChildren[0],'opacity',1)
    setStyle(liChildren[1],'color','#174994')
    if(!liChildren[3])return
    setStyle(li,'height',(towmenusHeight+liHeight+lisetBottom)/128+'rem')
    setStyle(liChildren[2],'transform','rotate(90deg)')
    setStyle(liChildren[3],'visibility','visible')
    setStyle(liChildren[3],'opacity','1')
}
// 获取左侧栏数据
const getLeftnavbackg = (tids) => {
    const obj = { id:tids?tids:route.params.tid }
    // 首页模块的id数组
    const homeIds = ['385','386','387','388',385,386,387,388]
    http.getBannernav_list(obj).then(res => {
        if(res.data.code == 200){
            data.enImg = res.data.data[0].url
            data.leftNavs = res.data.data[0].children
            data.title_en = res.data.data[0].title_en
            // 如果没有下级，就默认请求id为360的数据
            if(!data.leftNavs){
              if(homeIds.includes(obj.id)){
                    data.leftNavs = data.homenavs
                    const ind = data.homenavs.findIndex(x => x.id == route.params.id)
                    nextTick(() => { setleftscri(ind) })
              }
                data.idPrenArr = treeFindPath(res.data.data,(r) => r.id == route.params.id)
                animate(rightRouterview.value,100)
                return
            }
            // 根据id获取上级所有数据
            data.idPrenArr = treeFindPath(res.data.data,(r) => r.id == route.params.id)
            // 利用id查找对应的索引，给与左侧点亮
            if(route.params.level == 3){
                // 找出上级父id
                const levelTwo = data.idPrenArr.find(item => item.level == 2)
                // 根据id找索引
                const index =  data.leftNavs.findIndex(item => item.id == levelTwo.id)
                nextTick(() => { setleftscri(index) })
            }else{
                const index =  data.leftNavs.findIndex(item => item.id == route.params.id)
                nextTick(() => { setleftscri(index) })
            }
            animate(rightRouterview.value,100)
        }
    })
}
getLeftnavbackg(route.params.tid)
// 查找父节点方法
function treeFindPath (tree, func, path = []) {
        if (!tree) return []
        for (const item of tree) {
          // 这里按照你的需求来存放最后返回的内容吧
          path.push(item)
          if (func(item)) return path
          if (item.children) {
            const findChildren = treeFindPath(item.children, func, path)
            if (findChildren.length) return findChildren
          }
          path.pop()
        }
        return []
}
// 设置样式公用方法
const setStyle = (dom,styles,value) => {
    dom?dom.style[styles] = value:''
}
// 点击li跳转到详情
let pushArticle_xq = (e) => {
  router.push({
    name:'Labarticlexq',
    params:{
      articleid:e.id,
      id:route.params.id,
      tid:route.params.tid,
      level:route.params.level
    }
  })
}
miiter.on('onEnactilcetap',(e) => {
    setTimeout(() => animateStr(rightRouterview.value,0))
    setTimeout(() => { getLeftnavbackg(e) },100)
})
// 页面卸载清除事件总线
onUnmounted(() => {
    miiter.off('onEnactilcetap')
})
</script>

<style lang="less" scoped>
@maxw:128rem;
@family:'Microsoft YaHei';
.labbox{
  width: 100%;
  background-color: #fff;
.box{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between  ;
    width: 100%;
    height: 612/@maxw;
    background-color: #174994b1;
  .labbackground{
    position: absolute;
    width: 100%;
    height: 612/@maxw;
    vertical-align: middle;
  }
  .breadcrumbBox{
    position: relative;
    width: 100%;
    height: 107/@maxw;
    img{width: 100%;height: 100%;position: absolute;top: 0;left: 0;}
    .navcenter{
      position: relative;
      z-index: 9;
      width: 1588/@maxw;
      height: 100%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      transform: translateY(300px);
      opacity: 0;
      transition: ease .6s;
      .lefttext{
        width: 293/@maxw;
        height: 100%;
        color: #fff;
        font-size: 36/@maxw;
        font-family: @family;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .righttext{
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 22/@maxw;
        font-family: @family;
        cursor: pointer;
      }
    }
  }
  
}
.navbottom{
    overflow: hidden;
    margin: 0 auto;
    width: 1588/@maxw;
    display: flex;
    justify-content: space-between;
    .leftNavlist{
            position: relative;
            width: 293/@maxw;
            min-height: 543/@maxw;
            background:linear-gradient(150deg, #F0F0F0 0%,#E9E9E9 30% ,#C6C6C6 100%);
            transform: translateY(800px);
            opacity: 0;
            transition: ease .8s;
            box-sizing: border-box;
            padding: 5/@maxw 42/@maxw 20/@maxw 35/@maxw;
            nav{
                cursor: pointer;
                position: relative;
                z-index: 2;
                width: 100%;
                height: 71/@maxw;
                background-image: url("../../../assets/zhixian_.png");
                background-position:bottom;
                background-repeat: no-repeat;
                line-height: 71/@maxw;
                color: #6e6e6e;
                font-size: 22/@maxw;
                font-family:@family;
                transition: ease .3s;
                em{
                    opacity: 0;
                    transition: ease .3s;
                    position: absolute;
                    top: 25/@maxw;
                    display: inline-block;
                    width: 7/@maxw;
                    height: 21/@maxw;
                    background:linear-gradient(150deg, #174994 0% ,#387DE2 100%);
                }
                span{
                    display: inline-block;
                    overflow:hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    -o-text-overflow:ellipsis;
                    width: 80%;
                    margin-left: 22/@maxw;
                }
                i{
                    float: right;
                    font-style: normal;
                    transition: all .3s;
                    display: block;
                    width: 11/@maxw;
                    height: 19/@maxw;
                    margin-top: 26/@maxw;
                    background-image: url("../../../assets/left_icon.png");
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                }
                .towmenus{
                    width: 100%;
                    margin-top: -38/@maxw;
                    padding: 0;
                    opacity: 0;
                    visibility: hidden;
                    transition: ease .3s;
                    >section{
                        padding: 0;
                        width: 100%;
                        height: 35/@maxw;
                        display: flex;
                        justify-content: right;
                        align-items: center;
                        &:hover{color: #174994;}
                    }
                }
                &:hover{
                  em{ opacity: 1 !important; }
                  span{color: #174994;}
                }
            }
            >nav:last-child{
                background-image: url("");
            }
          &::after{
            position: absolute;
            bottom: 0;
            right: 0;
            width: 229/@maxw;
            height: 238/@maxw;
            content: '';
            background: url('../../../assets/enarticehua.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            z-index: 1;
        }
      }
    .rightRouterview{
          margin-top: 45/@maxw;
          width: 1224/@maxw;
          transform: translateY(800px);
          opacity: 0;
          transition: ease .6s;
          /deep/ a:hover{ 
            text-decoration: revert !important;
          } 
          /deep/ img{
            max-width: 100%;
          }
          /deep/ video{
            max-width: 100%;
            object-fit: cover;
          }
    }
}
}
@media screen and (max-width:750px) {
  @maxw:50rem;
  .labbox{
  width: 100%;
.box{
    height: 400/@maxw;
  .labbackground{
    height: 400/@maxw;
  }
  .breadcrumbBox{
    height: 107/@maxw;
    .navcenter{
      width: 655/@maxw;
      margin: 0;
      .lefttext{
        margin: 0;
        width: 293/@maxw;
        font-size: 36/@maxw;
      }
      .righttext{
        font-size: 22/@maxw;
      }
    }
  }
}
.navbottom{
    width: 655/@maxw;
    .leftNavlist{display: none !important;}
    .rightRouterview{
          margin-top: 45/@maxw;
          width: 655/@maxw;
    }
}
}
}
</style>  